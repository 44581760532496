import * as Sentry from "@sentry/nuxt";

Sentry.init({
    // If set up, you can use your runtime config here
    dsn: useRuntimeConfig().public.sentryDsn,
    integrations: [
        Sentry.replayIntegration({
            networkDetailAllowUrls: [
                "http://localhost:8787/chat/send-message",
                /^http:\/\/localhost:8787/,
                /^https:\/\/loving-multiply-kangaroo\.ngrok-free\.app/,
                /^https:\/\/voiceai-api-dev\.courserev\.ai/,
                /^https:\/\/voiceai-api\.courserev\.ai/,
                /^https:\/\/chatai-api-dev\.courserev\.ai/,
                /^https:\/\/chatai-api\.courserev\.ai/,
            ],
            networkRequestHeaders: ["*"],
            networkResponseHeaders: ["*"],
        }),
        Sentry.browserTracingIntegration(),
        Sentry.httpClientIntegration({
            failedRequestStatusCodes: [
                400, 401, 403, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413,
                414, 415, 416, 417, 421, 422, 423, 424, 425, 426, 428, 429, 431,
                451, 500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511,
            ],
        }),
    ],
    // Tracing
    // We recommend adjusting this value in production, or using a tracesSampler for finer control.
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/.*\.devchat\.courserev\.ai/,
        /^https:\/\/.*\.chat\.courserev\.ai/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
